import { useCallback } from 'react';

interface UseHubSpotReturn {
  loadWidget: () => () => void;
  removeWidget: () => () => void;
  isWidgetLoaded: () => boolean;
  openChat: () => void;
}

export function useHubSpot(): UseHubSpotReturn {
  const isWidgetLoaded = useCallback((): boolean => {
    return !!(typeof window !== 'undefined' && (window as any).HubSpotConversations?.widget);
  }, []);

  const loadWidget = useCallback((): (() => void) => {
    let retryCount = 0;
    const MAX_RETRIES = 50;

    const tryLoadWidget = () => {
      if (!isWidgetLoaded()) return false;

      // Add a small delay to ensure widget is ready for interactions
      setTimeout(() => {
        (window as any).HubSpotConversations.widget.load();
      }, 300);

      return true;
    };

    // Check for widget availability every 500ms
    const intervalId = setInterval(() => {
      const success = tryLoadWidget();
      retryCount++;

      if (success || retryCount >= MAX_RETRIES) {
        clearInterval(intervalId);
      }
    }, 500);

    // Cleanup interval on component unmount
    return () => {
      clearInterval(intervalId);
    };
  }, [isWidgetLoaded]);

  const openChat = useCallback(() => {
    (window as any).HubSpotConversations.widget.open();
  }, []);

  const removeWidget = useCallback((): (() => void) => {
    let retryCount = 0;
    const MAX_RETRIES = 50;

    const tryRemoveWidget = () => {
      if (!isWidgetLoaded()) return false;

      // Add a small delay to ensure widget is ready for interactions
      setTimeout(() => {
        (window as any).HubSpotConversations.widget.remove();
      }, 300);

      return true;
    };

    // Check for widget availability every 500ms
    const intervalId = setInterval(() => {
      const success = tryRemoveWidget();
      retryCount++;

      if (success || retryCount >= MAX_RETRIES) {
        clearInterval(intervalId);
      }
    }, 500);

    // Cleanup interval on component unmount
    return () => {
      clearInterval(intervalId);
    };
  }, [isWidgetLoaded]);

  return {
    loadWidget,
    removeWidget,
    isWidgetLoaded,
    openChat,
  };
}
