import IconButton from '@mui/material/IconButton';
import HelpIcon from '@mui/icons-material/Help';
import { useHubSpot } from '../../../../../hooks/useHubSpot';
import { Button, Typography, useTheme } from '@mui/material';
import { useRouter } from 'next/router';

const Support = ({ navHover }: { navHover: boolean }) => {
  const { loadWidget } = useHubSpot();
  const theme = useTheme();

  const router = useRouter();
  const titleStyles = {
    box: { display: 'flex', alignItems: 'center', cursor: 'pointer' },
    typography: { ml: 2, fontSize: '0.8rem', color: theme.palette.secondary.dark, fontWeight: 600 },
  };

  const handleClick = () => {
    router.push('/support');
    loadWidget();
  };

  return (
    <Button
      onClick={handleClick}
      variant='text'
      sx={{
        m: 0,
        p: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        '&:hover': {
          backgroundColor: theme.palette.grey[100],
        },
      }}
    >
      <IconButton>
        <HelpIcon />
      </IconButton>
      {navHover && <Typography sx={titleStyles.typography}>Support</Typography>}
    </Button>
  );
};

export default Support;
