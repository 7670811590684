import Script from 'next/script';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

export const HubSpot = () => {
  const router = useRouter();

  useEffect(() => {
    // Function to check if HubSpot widget is fully loaded
    const isHubSpotWidgetLoaded = () => {
      return !!(typeof window !== 'undefined' && (window as any).HubSpotConversations?.widget);
    };

    // Function to control widget visibility
    const toggleHubSpotWidget = () => {
      if (!isHubSpotWidgetLoaded()) return false;

      // Add a small delay to ensure widget is ready for interactions
      setTimeout(() => {
        if (router.pathname === '/support' || router.pathname === '/login') {
          (window as any).HubSpotConversations.widget.load();
        } else {
          (window as any).HubSpotConversations.widget.remove();
        }
      }, 300);

      return true;
    };

    let retryCount = 0;
    const MAX_RETRIES = 50;

    // Check for widget availability every 500ms
    const intervalId = setInterval(() => {
      const success = toggleHubSpotWidget();
      retryCount++;

      if (success || retryCount >= MAX_RETRIES) {
        clearInterval(intervalId);
      }
    }, 500);

    return () => {
      clearInterval(intervalId);
    };
  }, [router.pathname]);

  return (
    <Script defer async id='hs-script-loader' src='//js-na1.hs-scripts.com/47053343.js' strategy='afterInteractive' />
  );
};
