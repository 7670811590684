import { ObjectId } from 'bson';
import { GoogleCredentials } from 'libs/core/src/user/schema/google-credentials.schema';
import { AddressType, COMMUNICATION_MODE, ContactType, DIALECT, GenericObject, LANGUAGE } from '../common';
import { PaymentGateway, PaymentMethod } from '../transactions';
import { SmsConfigType } from './SmsConfigType';

export enum CURRENCY {
  USD = 'USD',
  EUR = 'EUR',
  GBP = 'GBP',
  INR = 'INR',
  MXN = 'MXN',
}

export type SettingsTypeClient = {
  currencyType: CURRENCY;
  defaultModeOfCommunication: COMMUNICATION_MODE;
  faEnabled: boolean;
  faq: Array<{ title: string; answer: string }>;
  minPaymentPercentage: number;
  conditionsForAutopilot: any;
  conditionsForCopilot: any;
  bucketSize?: number;
  bucketFrequency?: number;
  fdcpaEnabled: boolean;
  countryCode: string;
  ein: string;
  address: string;
  themeColor: string;
  holidays: string[];
  timezone: string;
  primaryColor: string;
  textColor: string;
  logo: string;
  paymentGateway?: PaymentGateway;
};

export type METADATA_CLIENT = {
  hostName: string;
  aggregatePayment: boolean;
  paymentData: {
    stripeAccountId: string;
    slackNotificationUrl: string;
  };
  collectionsAgentName: string;
  clientDisplayName: string;
  configuration: {
    enableTranslation: boolean;
  };
  communicationConfig: object;
};

export class ClientType {
  id: string;
  sourceId: string | null;
  name: string;
  isPrimary: boolean;
  parentId: string | null;
  childIds: string[];
  address: AddressType;
  contacts: ContactType[];
  supportNumbers: ContactType[];
  language: LANGUAGE;
  dialect: DIALECT | null;
  industryInformation?: string | null;
  smsConfig: SmsConfigType[];
  createdBy: string;
  updatedBy: string;
  createdAt: Date;
  updatedAt: Date;
  metadata: GenericObject;
  metadataDisplay: { [key: string]: string[] };
  settings?: Partial<SettingsTypeClient>;
  googleEmail: string;
  googleCredentials: GoogleCredentials;
  googleHistoryId: string;
  assignedUserIds: ObjectId[];
  isActive?: boolean;
  bankDetails?: ClientBankDetailType;
  paymentDetails: PaymentDetailsType;
}

export type ClientBankDetailType = {
  accountHolderName?: string;
  accountNumber: string;
  routingNumber: string;
  bankName?: string;
  bankLogoURL?: string;
  bankLabel?: string;
  bankMetadata?: { [key: string]: any };
};

export interface CreateBankAccountDtoType extends ClientBankDetailType {
  clientId: string;
}

export type ClientChargesType = {
  currency: 'usd';
  gateway: PaymentGateway;
  paymentMethod: PaymentMethod | any;
  charges: {
    type: 'FIXED' | 'PERCENTAGE';
    value: number;
  }[];
};

export type PaymentDetailsType = {
  defaultGateway: PaymentGateway;
  internalCharges: ClientChargesType[];
  externalCharges: ClientChargesType[];
};

export type ClientDocumentType = Omit<ClientType, 'id'> & { _id: string };
