import ClearIcon from '@mui/icons-material/Clear';
import { Box, Button, IconButton, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { ToastContainer as ReactToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { hexToRGBA } from '../@core/utils/hex-to-rgba';
import { clearAllToasts } from './Notifications';

export const ToastContainer = () => {
  const theme = useTheme();
  const [hasToasts, setHasToasts] = useState(false);

  useEffect(() => {
    const toastObserver = new MutationObserver(() => {
      const toastElements = document.querySelectorAll('.Toastify__toast');
      setHasToasts(toastElements.length > 0);
    });

    const toastContainer = document.querySelector('.Toastify');
    if (toastContainer) {
      toastObserver.observe(toastContainer, {
        childList: true,
        subtree: true,
      });
    }

    return () => toastObserver.disconnect();
  }, []);

  return (
    <>
      <ReactToastContainer
        position='top-right'
        theme='light'
        pauseOnHover
        draggable
        containerId='toast-container'
        style={{ paddingTop: '48px' }}
        toastStyle={{
          marginTop: '4px',
          width: '360px',
        }}
        closeButton={({ closeToast }) => (
          <IconButton
            size='small'
            onClick={e => {
              e.stopPropagation();
              closeToast(e);
            }}
            sx={{
              color: theme.palette.grey[600],
              '&:hover': {
                color: theme.palette.grey[900],
              },
            }}
          >
            <ClearIcon fontSize='small' />
          </IconButton>
        )}
      />

      {hasToasts && (
        <Box
          sx={{
            position: 'fixed',
            top: '1rem',
            // bottom: '1rem',
            right: '1rem',
            zIndex: 9999,
            width: '315px',
          }}
        >
          <Button
            variant='contained'
            fullWidth
            size='small'
            onClick={clearAllToasts}
            sx={{
              px: 2,
              py: 0.5,
              backgroundColor: 'white',
              color: theme.palette.grey[500],
              boxShadow: `0px 2px 4px ${hexToRGBA(theme.palette.primary.main, 0.28)}`,
              borderRadius: '30px',
              border: 'none',
              '&:hover': {
                border: 'none',
                color: theme.palette.primary.main,
                backgroundColor: theme.palette.customColors.themeColorLighter,
              },
            }}
          >
            <Typography sx={{ fontSize: '14px', color: theme.palette.primary.main, fontWeight: 600 }}>
              Clear All
            </Typography>
          </Button>
        </Box>
      )}
    </>
  );
};
